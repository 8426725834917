import React from 'react';
import ChampHand from "../images/champagne_hand.jpg"
import { Link } from "gatsby"

const WhyChooseUs = () => {
 return (
 	<>
		<div className="overflow-hidden relative h-auto py-10 lg:pb-16" id="aboutus" style={{background: "linear-gradient(rgba(87,87,87,1) 0%, rgba(0,0,0,1) 100%"}}>
			<div className="flex flex-col mx-auto md:flex-row md:w-5/6">
				<img src={ChampHand} alt="" className="image flex justify-center w-full px-10 pt-5 h-56 object-cover object-center opacity-90 md:h-auto md:w-3/5 md:pl-10 md:pr-5"/>
				<div className="copy my-3 px-10 flex-col content-around md:mt-3 md:w-4/5 md:pr-20 md:pl-0 lg:px-8">
					<h1 className="heroTitle text-3xl text-white font-default font-black uppercase text-center self-center">What sets us apart</h1>
					<p className="subTitle leading-8 pt-2 text-white font-serif">Because we care. That’s the short answer. Here you have one company to cover all your transportation needs. We are licensed to operate in all of Southern California’s airports like Los Angeles International Airport and all private FBO’s such as Van Nuys Airport.</p>
					<div className="buttonContainer pt-16">
						<Link to="/about-us" className="more text-white text-base bg-gray-800 border-none rounded hover:bg-gray-700 py-3 px-8 mr-3 self-center">More</Link>
						<a className="book text-black text-base bg-white border border-gray-600 rounded hover:bg-gray-200 py-3 px-8 mr-3 self-center" href="#reservations">
							Book
						</a>
					</div>
				</div>
			</div>
		</div>
	</>
 );
}

export default WhyChooseUs;