import React from "react";
import cadillacImage from "../images/cadillac-escalade-small.png";
import Person from "../svg/male.svg";
import Luggage from "../svg/luggage-cart.svg"

const Fleet = () => {
	return (
		<>
			<div className="fleet flex flex-col h-full my-5 sm:mt-5 h-auto" id="cars">
				<h1 className="title text-3xl font-default font-black uppercase text-center self-center px-10 mt-3">Luxury Vehicles</h1>
				<div className="content flex flex-col md:flex-row md:justify-center md:my-10">
					<img src={cadillacImage} alt="Cadillac Escalade" className="carImage flex self-center md:order-2 md:w-1/3 md:h-56 md:self-center md:mr-10 md:pr-10 lg:h-auto"/>
					<div className="car h-40 w-full sm:h-auto md:w-1/2 md:h-auto md:pl-10">
						<div className="carDescription flex flex-col">
							<h1 className="carName text-center text-xl font-extrabold  sm:pt-5 md:text-3xl md:text-left">Cadillac Escalade ESV</h1>
							<div className="carBlurb hidden sm:flex sm:self-center sm:w-1/2 sm:pt-2 md:self-start md:w-10/12">Loaded with premium features and an abundance of technology, the Cadillac Escalade makes everyday drives feel luxurious. Whether shuttling family, on the road for business, or heading out of town, this three-row SUV boasts a comfortable cabin with incredible capacity, as well as strong engine performance so you can pass, merge, and even tow with confidence. </div>
							<div className="icons flex justify-center my-5 md:hidden">
								<div className="person flex">
									<img src={Person} alt="" className="h-20"/>
									<span className="self-end mx-2 text-4xl font-extrabold"> - 5</span>
								</div>
								<div className="luggage flex self-end ml-5">
									<img src={Luggage} alt="" className="h-12"/>
									<span className="self-end ml-2 text-4xl font-extrabold"> - 4</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Fleet;