import React, {useEffect, useState} from "react";
import logoImage from '../images/logo.png';
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from 'gatsby-image';
import Instagram from "../svg/instagram.svg";
import Yelp from "../svg/yelp.svg";
import Facebook from "../svg/facebook-f.svg"
import Phone from "../svg/phone-alt.svg"
import Check from "../svg/check.svg"

const Banner = () => {
	const data = useStaticQuery(graphql`
	query MyQuery {
		allMarkdownRemark {
			nodes {
				id
				frontmatter {
					description
					slug
					title
				}
			}
		}
		banner_1: file(relativePath: {eq: "banner_1.jpg"}) {
			id
			childImageSharp {
				id
				fixed {
					...GatsbyImageSharpFixed
				}
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		},
		banner_2: file(relativePath: {eq: "banner_2.jpg"}) {
			id
			childImageSharp {
				id
				fixed {
					...GatsbyImageSharpFixed
				}
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		},
		banner_3: file(relativePath: {eq: "banner_3.jpg"}) {
			id
			childImageSharp {
				id
				fixed {
					...GatsbyImageSharpFixed
				}
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		},
		banner_4: file(relativePath: {eq: "banner_4.jpg"}) {
			id
			childImageSharp {
				id
				fixed {
					...GatsbyImageSharpFixed
				}
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
	`);

	const banners = [
		{
			image: data.banner_1.childImageSharp.fluid,
			name: "banner_1"
		},
		{
			image: data.banner_2.childImageSharp.fluid,
			name: "banner_2"
		},
		{
			image: data.banner_3.childImageSharp.fluid,
			name: "banner_3"
		},
		{
			image: data.banner_4.childImageSharp.fluid,
			name: "banner_4"
		}
	];

	const [isFirstRender,setIsFirstRender] = useState(true);
	const [sliderMax, setSliderMax] = useState(0);
	const [bannerSlider, setBannerSlider] = useState(() => {
		const tempSlider = [];
		for(let i = 0; i <= sliderMax; i++) {
			tempSlider.push(banners[i]);
		}
		return tempSlider;
	});
	const bannerMax = banners.length;
	// Handle Email Submission

	useEffect(() => {
		const interval = setInterval(() => {
			setSliderMax((sliderMax === bannerMax - 1) ? 0 : sliderMax + 1)
		}, 3500);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[sliderMax]);

	useEffect(function nextSlide(){
		if(!isFirstRender) {
			const currentBannerSlider = bannerSlider;
			currentBannerSlider.shift();
			currentBannerSlider.push(banners[sliderMax]);
			setBannerSlider([...currentBannerSlider]);
		}
		setIsFirstRender(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[sliderMax]);

	return (
		<div className="banner w-full bg-white overflow-hidden h-auto mb-2 box-border md:mb-40">
			<div className="banner__header w-full bg-gradient-to-t from-gray-300 shadow-md">
				<div className="relative pt-3 px-4 sm:px-6 lg:px-8 h-56">
					<nav className="flex flex-col items-center justify-between sm:h-10 lg:justify-start">
						<div className="flex w-full border-solid border-b-2 border-gray-200 pb-3 md:border-0">
							<div className="flex justify-start w-full md:hidden">
								<img src={Phone} className="h-5 w-5 mr-2 self-center" alt="Phone Icon"/>
								<a href="tel:1-818-331-1248" className="self-center text-sm font-default font-extrabold">+1-818-331-1248</a>
							</div>
							<div className="flex justify-end w-full">
								<a href="https://www.instagram.com/cadiblackconcierge/"><img src={Instagram} className="h-8 w-8 mr-3" alt="Instagram Icon"/></a>
								<a href="https://www.facebook.com/Cadiblack-Concierge-113396697046727/"><img src={Facebook} className="h-8 w-8 mr-3" alt="Facebook Icon"/></a>
								<a href="https://yelp.to/qTKq/u7fbTkvLA9"><img src={Yelp} className="h-8 w-8" alt="Yelp Icon"/></a>
							</div>
						</div>
						<div className="mt-5 flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
							<div className="flex items-center w-full">
								<a href="/" aria-label="Home" className="flex justify-center w-full">
									<span className="flex self-center text-5xl font-default font-extrabold md:mr-3">Cadiblack</span>
									<img src={logoImage} alt="" className="justify-self-end h-24 w-24"/>
									<span className="hidden sm:flex self-center text-5xl ml-3 font-default font-extrabold ">Concierge</span>
								</a>
							</div>
						</div>
						<div className="flex justify-around pt-4 w-full sm:flex md:flex md:ml-10 md:pr-4 lg:w-2/3">
							<Link
								to="/about-us"
								className="hidden font-default font-black text-gray-700 uppercase hover:text-black transition duration-150 ease-in-out sm:flex">
								About Us</Link>
							<Link
								to="/#cars"
								className="hidden font-default font-black text-gray-700 uppercase hover:text-black transition duration-150 ease-in-out sm:flex">
								Luxury Vehicles</Link>
							<Link
								to="/#services"
								className="font-default font-black text-gray-700 uppercase hover:text-black transition duration-150 ease-in-out">
								Services
							</Link>
							<Link
								to="/#reservations"
								className="font-default font-black text-gray-700 uppercase hover:text-black transition duration-150 ease-in-out">
								Reserve Now</Link>
						</div>
					</nav>
				</div>
			</div>
			<Img fluid={bannerSlider[0].image} fadeIn={true} className="z-0 w-full py-6 opacity-40 xl:h-full transition duration-500 ease-in-out" style={{position: "absolute"}}/>
			<div className="banner_content_overlay relative flex flex-col mb-24 items-center justify-around z-10 md:h-auto">
				<span className="banner_content__heroTitle text-center mt-16 text-3xl font-default font-black md:text-5xl md:mt-32">
					Where Comfort Meets Luxury
				</span>
				<div className="bannerCTAButtons flex mt-4 self-center w-5/6 justify-center">
					<a href="tel:1-818-331-1248" className="quoteButton font-serif font-bold text-white text-sm bg-gray-800 border-none rounded hover:bg-gray-700 py-2 px-4 self-center">Free Quote</a>
					<a href="#reservations" className="reservationButton font-serif font-bold text-white text-sm bg-gray-800 border-none rounded hover:bg-gray-700 py-2 px-4 ml-3 self-center">Reservations</a>
				</div>
				<div className="signUpNewsLetter hidden mt-12 mb-10 w-2/3 justify-center md:w-1/2 lg:w-1/3">
					<input type="text" placeholder="Newsletter Sign Up" className="h-8 w-3/4 px-2 font-serif " />
					<button type="submit" className="py-1 px-3 bg-gray-800 hover:bg-gray-700"><img src={Check} alt="Checkmark" className="h-5 w-5"/></button>
				</div>
			</div>
		</div>
	);
};

export default Banner;