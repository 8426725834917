import React from "react";

const ReservationSubmitted = () => {
	return (
		<div className="bg-white w-4/5 mx-auto rounded p-5 xl:w-2/5">
			<h1 className="hero text-black text-3xl py-3">
				Book A Reservation
			</h1>
			<h4 className="subtitle text-black text-xl pb-5">
				A Representative will be reaching out shortly.
			</h4>
			<h4 className="subtitle text-black text-xl pb-5">
				The form has been submitted.
			</h4>
			<div className="InputGroup">
				<div data-netlify-recaptcha="true" style={{display:"none"}} />
			</div>
		</div>
	)
}

export default ReservationSubmitted