import React, { useState, useEffect } from "react";
import Img from 'gatsby-image';
import {useStaticQuery, graphql, Link} from "gatsby";
import Carousel from 'react-elastic-carousel';
import "../styles/carousel.css";

const Services = () => {
	const data = useStaticQuery(graphql`
		query ServiceImages {
			services__seaportService: file(relativePath: {regex: "/boat-small.png/"}) {
				id
				childImageSharp {
				  fixed {
					...GatsbyImageSharpFixed
				  }
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
			services__carService: file(relativePath: {regex: "/airport-small.png/"}) {
				id
				childImageSharp {
				   fixed {
						...GatsbyImageSharpFixed
					}
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
			services__corpTravel: file(relativePath: {regex: "/phone_business-small.png/"}) {
				id
				childImageSharp {
				   fixed {
						...GatsbyImageSharpFixed
					}
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
			services__ptopService: file(relativePath: {regex: "/point-to-point.jpg/"}) {
				id
				childImageSharp {
				   fixed {
						...GatsbyImageSharpFixed
					}
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
			services__hourlyService: file(relativePath: {regex: "/hourly_car_service.jpg/"}) {
				id
				childImageSharp {
				   fixed {
						...GatsbyImageSharpFixed
					}
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
			services__eventService: file(relativePath: {regex: "/long_distance.jpg/"}) {
				id
				childImageSharp {
				   fixed {
						...GatsbyImageSharpFixed
					}
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	const services = [
		{
			name: "Corporate Car Service",
			image: data.services__corpTravel.childImageSharp.fluid,
			class: "services__corpTravel",
			link: "/corporate-travel-car-service"
		},
		{
			name: "Airport Car Service",
			image: data.services__carService.childImageSharp.fluid,
			class: "services__carService",
			link: "/airport-car-service"
		},
		{
			name: "Seaport Car Service",
			image: data.services__seaportService.childImageSharp.fluid,
			class: "services__seaportService",
			link: "/seaport-car-service"
		},
		{
			name: "Site to Site Car Service",
			image: data.services__ptopService.childImageSharp.fluid,
			class: "services__ptopService",
			link: "/point-to-point-car-service"
		},
		{
			name: "Hourly Car Service",
			image: data.services__hourlyService.childImageSharp.fluid,
			class: "services__hourlyService",
			link: "/hourly-car-service"
		},
		{
			name: "Event Car Service",
			image: data.services__eventService.childImageSharp.fluid,
			class: "services__eventService",
			link: "/event-car-service"
		},
	];

	const [width, setWidth] = useState(undefined);
	const [sliderMax, setSliderMax] = useState(() => {
		if (width < 640) {
			return services.length - 1;
		} else if (width > 640 && width < 768) {
			return ((services.length/2) - 1);
		} else if (width > 768) {
			return 2;
		}
	});

	// const [serviceSlider, setServiceSlider] = useState(() => {
	// 	const tempSlider = [];
	// 	for(let i = 0; i <= sliderMax; i++) {
	// 		tempSlider.push(services[i]);
	// 	}
	// 	return tempSlider;
	// });
	// const [sliderStart, setSliderStart] = useState(0);
	// const [serviceToDisplay, setServiceToDisplay] = useState(0);

	useEffect(() => {
		function handleResize(){
			setWidth(window.innerWidth);
		}
		window.addEventListener('resize', handleResize);
		handleResize();
	});

	useEffect(() => {
		if (width <= 640) {
			setSliderMax(1);
		} else if (width > 640 && width < 768) {
			setSliderMax(2);
		} else if (width >= 768) {
			setSliderMax(3);
		}
	},[width]);

	// useEffect(function loadGallery(){
	// 	const currentServiceSlider = serviceSlider;
	// 	currentServiceSlider.shift();
	// 	const tempSlider = [];
	// 	for(let i = sliderStart; i <= (sliderMax + sliderStart) ; i++) {
	// 		tempSlider.push(services[i]);
	// 	}
	// 	setServiceSlider([...tempSlider]);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [sliderMax, sliderStart]);

	// const setServiceSlide = (slideNumber) => {
	// 	setServiceToDisplay(slideNumber);
	// }

	// useEffect(() => {
	// 	// const currentServiceSlider = serviceSlider;
	// 	// currentServiceSlider.shift();
	// 	// currentServiceSlider.push();
	// 	const slider = [services[serviceToDisplay]];
	// 	setServiceSlider([...slider]);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [serviceToDisplay])

	return (
		<>
			<div className="services bg-black h-full md:py-8" id='services'>
				<div className="content flex flex-col w-5/6 mx-auto">
					<h1 className="hero text-white self-center text-3xl font-extrabold uppercase self-center mt-8">
						Services
					</h1>
					<h2 className="subtitle text-white text-xl text-center uppercase self-center my-3">
						Luxury Services On Demand
					</h2>
					<div className="contentBoxes my-5">
						<div className="sm:flex sm:h-auto sm:w-full">
							<Carousel itemsToScroll={sliderMax} itemsToShow={sliderMax}>
								{services.map((service, index) => (
									<div className={service.class + `serviceBox flex flex-col justify-around mb-2 w-11/12 h-full`} key={index}>
										<Img
											fluid={service.image}
											className="serviceImage rounded-t-lg"
										/>
										<div className="serviceFooter h-24 bg-white flex flex-col items-center">
											<span className="serviceName text-black font-extrabold my-3">
												{service.name}
											</span>
											<Link to={service.link} className="serviceButton more text-black text-base bg-white border-2 border-gray-800 rounded hover:bg-gray-100 py-1 px-4 self-center">More</Link>
										</div>
									</div>
								))}
							</Carousel>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Services;