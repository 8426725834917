import React from 'react';
import ReservationImage from "../images/reservation-bg.png"
import ReservationSubmitted from "./ReservationSubmitted";

const encode = (data) => {
	return Object.keys(data)
	.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
	.join("&");
}

class Reservation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {},
			submitted: 0
		};
		this.handleChange = this.handleChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}

	submitForm = async (e) => {
		e.preventDefault();
		const result = await fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({ "form-name": "reservationForm", ...this.state.form })
		});

		this.setState({submitted:++this.state.submitted});
	}

	handleChange = async (e) => {
		let inputName = e.target.name;
		let tempForm = this.state.form;
		this.setState({form: {...tempForm,  [inputName]: e.target.value}});
	}

	render() {

		return(
			<>
				{!this.state.submitted ? <div className="reservationContainer h-full py-10 px-5 bg-cover" id="reservations" style={{backgroundImage: `url(${ReservationImage})`}}>
					<form className="background" data-netlify="true" data-netlify-honeypot="bot-field" name="reservationForm" action="/" method="POST">
						<input type="hidden" name="form-name" value="contact" hidden/>
						<div className="bg-white w-4/5 mx-auto rounded-lg shadow-2xl p-5 sm:w-3/5 md:w-3/5 lg:w-2/5 xl:w-1/3">
							<h1 className="hero text-black text-3xl py-3">
								Book A Reservation
							</h1>
							<h4 className="subtitle text-black text-xl pb-5">
								A Representative will be reaching out shortly.
							</h4>
							<div className="inputGroup my-3">
								<label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Name</label>
								<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" id="name" name="name" placeholder="John Doe" onChange={this.handleChange} required/>
							</div>
							<div className="inputGroup my-3">
								<label htmlFor="emZail" className="block text-gray-700 text-sm font-bold mb-2">E-Mail</label>
								<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="email" id="email" name="email" placeholder="jdoe@email.com" onChange={this.handleChange} required/>
							</div>
							<div className="inputGroup my-3">
								<label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">Phone</label>
								<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="tel" id="phone" name="phone" placeholder="123-456-7890" onChange={this.handleChange} required/>
							</div>
							<div className="inputGroup my-3" >
								<label htmlFor="date" className="block text-gray-700 text-sm font-bold mb-2">Date [use the 00-00-0000 format]</label>
								<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" id="date" name="date" placeholder="01-01-2020" onChange={this.handleChange} required/>
							</div>
							<div className="inputGroup my-3">
								<label htmlFor="passengers" className="block text-gray-700 text-sm font-bold mb-2">Number of Passengers:</label>
								<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" id="passengers" name="passengers" placeholder="Number of Passengers" onChange={this.handleChange}/>
							</div>
							<div className="flex flex-wrap my-3 mb-6">
								<div className="w-full md:w-1/2 mb-6 md:mb-0 md:pr-3">
									<label className="text-gray-700 text-sm font-bold mb-2" htmlFor="fromLocation">
										From Location
									</label>
									<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="fromLocation" id="fromLocation" type="text" placeholder="LAX" onChange={this.handleChange}/>
								</div>
								<div className="w-full md:w-1/2">
									<label className="text-gray-700 text-sm font-bold " htmlFor="toLocation">
										To Location
									</label>
									<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="toLocation" id="toLocation" type="text" placeholder="The Standard Hotel" onChange={this.handleChange} />
								</div>
							</div>
							<div className="flex flex-wrap my-3 mb-6">
								<div className="w-full md:w-1/2 mb-6 md:mb-0 md:pr-3">
									<label htmlFor="hours" className="block text-gray-700 text-sm font-bold mb-2">Reservation Hours</label>
									<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" id="hours" name="reservation_hours" placeholder="Estimated Number of Hours" onChange={this.handleChange}/>
								</div>
								<div className="w-full md:w-1/2 mb-6 md:mb-0">
									<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fromTime">
										Pickup Time
									</label>
									<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="fromTime" id="fromTime" type="time" onChange={this.handleChange}/>
								</div>
							</div>
							<div className="inputGroup my-3">
								<label htmlFor="car" className="block text-gray-700 text-sm font-bold mb-2">Car Type</label>
								<input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" id="car" name="cartype" value="Cadillac Escalade ESV" disabled/>
							</div>
							<div className="reservationNextButton flex justify-end">
								<div className="flex">
									<button className="nextReservation text-white rounded px-3 py-2 bg-black" type="submit" onClick={this.submitForm}>Submit</button>
								</div>
							</div>
							<div className="InputGroup">
								<div data-netlify-recaptcha="true" style={{display:"none"}} />
							</div>
						</div>
					</form>
				</div> : <ReservationSubmitted />}
			</>
		);
	}
}

export default Reservation;