import React from "react";
import { Link } from "gatsby"
import Instagram from "../svg/instagram.svg";
import Yelp from "../svg/yelp.svg";
import Facebook from "../svg/facebook-f.svg";

const Footer = () => {
	return (
		<>
			<footer className="footer flex bg-black justify-around p-5 md: md:h-48">
				<section className="footer__contactUs flex text-white h-auto w-4/5 md:flex md:justify-self-auto md:w-1/3">
					<span className="contactUs__title text-base font-extrabold pl-5 md:pt-8 md:flex md:h-12 md:pl-0 md:origin-middle md:transform md:-rotate-90 md:pr-5">
						Contact
					</span>
					<div className="contactUs__content flex flex-col ml-1">
						<a href="tel:1-818-331-1248" className="contactUs__link text-base">+1-818-331-1248</a>
						<a href="malto:cadiblackconcierge@gmail.com" className="contactUs__link hidden md:flex ">cadiblackconcierge@gmail.com</a>
					</div>
				</section>
				<section className="footer__services hidden text-white h-auto w-4/5 md:flex md:justify-self-auto md:w-1/3">
					<span className="title text-white font-extrabold hidden md:pt-8 md:pr-6 md:flex md:h-12 md:pl-0 md:origin-middle md:transform md:-rotate-90 ">
						Services
					</span>
					<div className="content w-full flex flex-col  ml-1">
						<Link to="#aboutus" className="link text-white">About Us</Link>
						<Link to="#cars" className="link text-white">Luxury Vehicles</Link>
						<Link to="#services" className="link text-white">Services</Link>
						<Link to="#reservations" className="link text-white">Reservations</Link>
					</div>
				</section>
				<section className="footer__social flex justify-end w-1/3 md:justify-center ">
					<a href="https://www.instagram.com/cadiblackconcierge/"><img src={Instagram} className="socialIcon h-8 w-8 mr-2" alt="Instagram Icon"/></a>
					<a href="https://www.facebook.com/Cadiblack-Concierge-113396697046727/"><img src={Facebook} className="socialIcon h-8 w-8 mr-2" alt="Facebook Icon"/></a>
					<a href="https://yelp.to/qTKq/u7fbTkvLA9"><img src={Yelp} className="socialIcon h-8 w-8" alt="Yelp Icon"/></a>
				</section>
			</footer>
		</>
	)
}

export default Footer